// App.js
import React, { useState, useEffect } from 'react';
import { Col, Row, Container } from "react-bootstrap";
import ReactGA from 'react-ga4';
import TextAreaUser from '../components/TextareaUser';
import TButton from '../components/TButton';
import ResultBox from '../components/ResultBox';
import Slider from '../components/Slider';
import Signup from './signup';
import { Circles } from 'react-loader-spinner'
import handleTranslateText from '../utils/translationUtils';
import handleAnalyzeText from '../utils/analyzeUtils';
import sanitizeInput from '../utils/generalUtils';
import { useUserAuth } from "../components/UserAuthContext";
import Teseoplus from '../components/Teseoplus';


function Home() {
  const { user } = useUserAuth();

  //const [verified, setVerified] = useState(false);
  const [inputText, setInputText] = useState('Curate ut valeatis');
  const [translatedText, setTranslatedText] = useState('');
  const [error, setError] = useState(false);
  const [textError, setTextError] = useState('');
  const [analysis, setAnalysis] = useState({});
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0); // Progress state
  const [disabled, setDisabled] = useState(true);
  const [openSignup, setOpenSignup] = useState(false);
  const [usageCounter, setUsageCounter] = useState(0); 

 useEffect(() => {
  // Get contatore iniziale LATINO
  // async function fetchData() {
  //   const counter = await getCounterOnDb();
  //   setUsageCounter(counter);
  //   //console.log("Counter iniziale: ", counter, "/70 volte");
  // }
  // fetchData();

  document.body.classList.add('home-page');
  return () => {
    document.body.classList.remove('home-page');
    };
  }, []);
  
  // async function getCounterOnDb() {
  //   if(user.uid === undefined) return;
  //   const userQuerySnapshot = await getDocs(query(usersCollection, where("uid", "==", user.uid)))  // TODO: ottimizzare le chiamate get al db
  //   if (userQuerySnapshot.size > 0) {
  //       const docSnapshot = userQuerySnapshot.docs[0];
  //       const data = { ...docSnapshot.data(), id: docSnapshot.id };
  //       // Check if latino_count field exists, if not, create one with a default value of 0 (for already registered users)
  //       if (!data.hasOwnProperty('latino_count')) {
  //         // Update the document to add the latino_count field with a value of 0
  //         await updateDoc(docSnapshot.ref, { latino_count: 0 });
  //         data.latino_count = 0;
  //       }
  //       //console.log("Counter iniziale: ", data.latino_count, "/70 volte");
  //       return data.latino_count;
  //   }
  // }

  // useEffect(() => {
  //   updateCounterOnDb();
  // }, [usageCounter]);

  // async function updateCounterOnDb() {
  //   if(user.uid === undefined) return;
  //   try {
  //     const userQuerySnapshot = await getDocs(query(usersCollection, where("uid", "==", user.uid)));
  //     if (userQuerySnapshot.size > 0) {
  //       const docSnapshot = userQuerySnapshot.docs[0];
  //       await updateDoc(docSnapshot.ref, { latino_count: usageCounter });
  //       //console.log("Counter aggiornato: ", usageCounter, "/70 crediti mensili usati");
  //     }
  //   } catch (error) {
  //     console.error("Error updating counter on Firestore:", error);
  //   }
  // }

  const handleUpdateError = (errorText) => {
    setTextError(errorText);
    setError(true);
  }  

  const handleTranslate = async (lang) => {
    if(!user) {
      setOpenSignup(true);
    }
    else {
      ReactGA.event({
        category: 'Button',
        action: 'Click button translate',
        label: 'Translate text',
      });
      setUsageCounter(usageCounter + 1);
      handleTranslateText(user.uid, inputText, lang, setLoading, setTranslatedText, setError, setTextError, handleUpdateError, setDisabled);
    }
  }

  const handleUpdateAnalysis = (partialData, i, len) => {
    // Update component state with partial analysis data
    setAnalysis(prevData => ({ ...prevData, ...partialData }));
    setProgress((i + 1) / len * 100);
  };

  const handleAnalysis = async (lang) => {

    if(!user) {
      setOpenSignup(true);
    }
    else {

    ReactGA.event({
      category: 'Button',
      action: 'Click button analisi',
      label: 'Analyzed text',
    });
    setUsageCounter(usageCounter + 1);
    handleAnalyzeText(user.uid, inputText, lang, setLoading, setAnalysis, setProgress, setTextError, setError, handleUpdateError, handleUpdateAnalysis, setDisabled);
    }
  };

  const cleanInput = (event) => {
    let textSanitized = sanitizeInput(event);
    if(textSanitized === "") {
      setDisabled(true);
    }
    else {
      setInputText(textSanitized);
      setDisabled(false);
    }
  }

  const closePopup = () => {
    setOpenSignup(false);
  }


  return (
    <main className="App">
        <Container>
          {openSignup && <div className='blurred-background'></div>}
          {openSignup && <Signup closePopup={closePopup} popup={true}></Signup>}
          <Row className='my-5'>
            <span className='bold fontXXL white text-center'>Le versioni di latino sono un labirinto?</span>  
            <span className='regular fontXL white text-center'>Traduci e analizza la tua versione con l'intelligenza artificiale</span>
          </Row>
          <Row className='align-items-center justify-content-center mb-5 mx-2'>
            <TextAreaUser initialize="Incolla qui la tua versione di latino" setTextInput={cleanInput} readOnly={false}></TextAreaUser>
            <span className='text-center regular fontS white mt-1'>{textError}</span>
            <Col sm={11} md={8} lg={8} xs={11} className="d-flex justify-content-center mt-4">
              {loading ? 
                <Circles
                height="40"
                width="40"
                color="#BB0A0B"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                />
              : 
              ""
              }
                <TButton onClick={() => handleTranslate("lat")} text='Traduci' disabled={disabled}></TButton>
                <TButton onClick={() => handleAnalysis("lat")} text='Analizza' disabled={disabled}></TButton>
            </Col>
          </Row>

          <Row className='justify-content-center'>
              <ResultBox object={translatedText} isAnalisi={false} error={error}></ResultBox>
              <ResultBox object={analysis} isAnalisi={true} error={error} loading={loading} progress={progress}></ResultBox>
          </Row>
            
          <Row className="mt-5 mb-5">
            <Slider />
          </Row>

          <Teseoplus openPopup={setOpenSignup}></Teseoplus>
        </Container>

        {/*<ReCAPTCHA
              sitekey='6Lf98acpAAAAAEuaeoeilZXSmtwmVHnNTzOisZV2'
              onChange={handleRecaptchaChange} // Callback for reCAPTCHA checkbox change
        />*/}  
    </main>
  );

}

export default Home;