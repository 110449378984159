import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useUserAuth } from "../components/UserAuthContext";
import GoogleButton from "react-google-button";

import styles from "../styles/PopupTeseo.module.css";
import { IoMdClose } from "react-icons/io";

const Signup = ({closePopup, popup}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [nome, setNome] = useState("");
    const [scuola, setScuola] = useState("");
    const [anno, setAnno] = useState("");
    const [error, setError] = useState("");
    const [step, setStep] = useState(1); // State to track the step
    const { googleSignIn, signUp } = useUserAuth();
    let navigate = useNavigate();

    const handleSubmitStep1 = (e) => {
        e.preventDefault();
        setError("");
        if (scuola && anno && nome) {
            setStep(2); // Proceed to step 2
        } else {
            setError("Completa tutti i campi prima di continuare!");
        }
    };

    const handleSubmitStep2 = async (e) => {
        e.preventDefault();
        setError("");
        try {
            if (!email || !password) {
                setError("Completa tutti i campi prima di continuare!");
            } else {
                await signUp(email, password, scuola, anno, nome);
                navigate("/profilo");
            } 
        } catch (err) {
            if (err.message === "Firebase: Error (auth/email-already-in-use).") {
                setError("Email già in uso");
            } else if (err.message === "Firebase: Password should be at least 6 characters (auth/weak-password).") {
                setError("Password troppo debole, deve contenere almeno 6 caratteri");
            } else if (err.message === "Firebase: Error (auth/invalid-email).") {
                setError("Email non valida");
            } else {
                setError(err.message);
            }
        }
    };

    const handleGoogleSignIn = async (e) => {
        e.preventDefault();
        setError("");
        try {
            const success = await googleSignIn(scuola, anno, nome);
            if(success) {
                navigate("/profilo");
            } else {
                setError("Errore nell'accesso con Google");
            }
        } catch (error) {
            setError(error.message);
            //console.log(error.message);
        }
    };

    return (
        <>
        <div className={styles.normalSizeBox}>
            <div className={`${popup ? styles.popup_container : ''} d-flex align-items-center justify-content-center align-items-center`}>
                <div className={`${styles.boxTeseo} white pt-4 px-5`}>
                    {popup ?
                        <div className={`${styles.icon_close} fontL d-flex align-items-center justify-content-center`} onClick={closePopup}>
                        <IoMdClose />
                        </div>
                    :
                        null 
                    }
                    {step === 1 && (
                        <>
                            <h2 className="bold text-center" >Registrati</h2>
                            <div className="mb-4 box text-center regular fontM">
                            Hai già un account? <Link to="/login" className="linkTeseo">Log In</Link>
                            </div>
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Form onSubmit={handleSubmitStep1}>
                                <Form.Group className="mb-3" controlId="formNameSurname">
                                    <Form.Control
                                        type="text"
                                        placeholder="Nome e Cognome"
                                        onChange={(e) => setNome(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 regular" controlId="formScuola">
                                    <Form.Label>Seleziona la tua scuola:</Form.Label>
                                    <Form.Select
                                        onChange={(e) => setScuola(e.target.value)}
                                    >
                                        <option value="">Seleziona</option>
                                        <option value="classico">Liceo Classico</option>
                                        <option value="scientifico">Liceo Scientifico</option>
                                        <option value="linguistico">Liceo Linguistico</option>
                                        <option value="pedagogico">Liceo Pedagogico</option>
                                        <option value="universita">Università</option>
                                        <option value="altro">Altro</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3 regular" controlId="formAnno">
                                    <Form.Label>Seleziona in quale anno sei:</Form.Label>
                                    <Form.Select
                                        onChange={(e) => setAnno(e.target.value)}
                                    >
                                        <option value="">Seleziona</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </Form.Select>
                                </Form.Group>

                                <div className="d-grid gap-2">
                                    <Button variant="primary" type="Submit" className="buttonTeseo bold fontL">
                                        Continua
                                    </Button>
                                </div>
                            </Form>
                        </>
                    )}

                    {step === 2 && (
                        <>
                            <h2 className="mb-3 bold text-center regular">Sign Up</h2>
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Form onSubmit={handleSubmitStep2}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control
                                        type="email"
                                        placeholder="Indirizzo Email"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Form.Group>

                                <div className="d-grid gap-2">
                                    <Button variant="primary" type="Submit" className="buttonTeseo bold fontL">
                                        Registrati
                                    </Button>
                                </div>
                                <div>
                                    <div className="regular fontS text-center mt-2">Proseguendo accetti i termini di<a href="https://www.iubenda.com/privacy-policy/27118703" target="_blank" className='regular white m-2'>Privacy Policy</a></div>
                                </div>
                                <hr />
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <GoogleButton
                                        className="g-btn"
                                        type="light"
                                        onClick={handleGoogleSignIn}
                                    />
                                </div>
                            </Form>
                        </>
                    )}
                </div>
            </div>
        </div>
        </>
    );
};

export default Signup;
