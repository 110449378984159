import axios from 'axios';
import divideText from './divideText';
import { addDoc, serverTimestamp } from "firebase/firestore";
const { translationCollection } = require('../config/firebase-config');

const API_URL = process.env.REACT_APP_BACKEND_PATH;

const TEXT_ERROR_MESSAGES = {
    400: 'Teseo traduce solo testi latini!',
    429: 'Teseo ha lavorato troppo per te oggi, torna domani.',
    default: 'Teseo si è perso nel labirinto e non può aiutarti ora.'
};

const translateText = async (uid, inputText, lang, updateError) => {
    let textToTranslate;
    let textTranslated = "";

    if (lang === "gre") {
        textToTranslate = divideText(inputText, 70); // Divide the text into subtexts
        for (let i = 0; i < textToTranslate.length; i++) {
            const translation = await callToTranslate(textToTranslate[i], lang, updateError);
            textTranslated += translation;
        }
    } else {
        textToTranslate = inputText;
        textTranslated = await callToTranslate(textToTranslate, lang, updateError);
    }
    saveDb(uid, inputText, textTranslated, lang);
    return textTranslated;
};

const callToTranslate = async (inputText, lang, updateError) => {
    try {
        const response = await axios.post(
            `${API_URL}/translate`,
            {
                text: inputText,
                lang: lang,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            }
        );

        return response.data.translatedText;
    } catch (error) {
        handleTranslateError(error, updateError);
    }
};


const handleTranslateError = (error, updateError) => {
    let errorMessage = 'Teseo non risponde'; // Default error message

    if (error.response) {
        const statusCode = error.response.status;
        errorMessage = TEXT_ERROR_MESSAGES[statusCode] || TEXT_ERROR_MESSAGES.default;
        updateError(errorMessage);
    } else if (error.request) {
        console.error('Request Error:', error.request);
    } else {
        console.error('Request Setup Error:', error.message);
    }

    throw new Error(errorMessage);
};

const saveDb = async(uid, inputText, outputText, lang) => {
    try {
        await addDoc(translationCollection, {  
            uid: uid,
            input: inputText,
            output: outputText,
            language: lang,
            timestamp: serverTimestamp() 
        });
    } catch (error) {
        console.log("ERROR on translation db: ", error);
    }
}

export default translateText;
