import {Link, useParams} from 'react-router-dom';
import styles from "../styles/Checkout.module.css"
import GButton from '../components/GButton';
import {handlePaymentNo} from "../utils/stripeUtils";

import React, { useEffect } from 'react';

const CheckoutNo = () => {

    const { userid } = useParams();

    useEffect(() => {
        handlePaymentNo(userid);
      }, []);

    return(
        <div className={`${styles.checkoutPage} d-flex align-items-center justify-content-center flex-column`}>
            <div className={styles.checkoutBox}>
                <h1 className='bold fontXXXL mb-1'>
                    Mmm, qualcosa non ha funzionato!
                </h1>
                <span className='mb-5 justify-content-center d-flex regular'>Il tuo pagamento non è andato a buon fine.</span>
                <Link to={'/'} className={styles.navLink}><GButton text={'Area personale'}></GButton></Link>
            </div>
        </div>
    );
};


export default CheckoutNo;