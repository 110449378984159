import {Link, useParams} from 'react-router-dom';
import styles from "../styles/Checkout.module.css"
import GButton from '../components/GButton';
import {handlePaymentYes} from "../utils/stripeUtils";
import { getDocs, query, where, updateDoc } from "firebase/firestore";
import { usersCollection } from "../config/firebase-config";
import checkEmail from "../utils/mailchimpCheck";
import { useUserAuth } from "../components/UserAuthContext";

import React, { useEffect } from 'react';

const CheckoutYes = () => {
    const { userid } = useParams();
    const { user } = useUserAuth();

    async function updateTypeOnDb() {
        if (user.uid === undefined) return;
        if (user.uid === userid) {
            //const isSubscribed = await checkEmail(user.email);
            //console.log("Is subscribed: ", isSubscribed);

            const userQuerySnapshot = await getDocs(query(usersCollection, where("uid", "==", userid)))
            if (userQuerySnapshot.size > 0) {
                const docSnapshot = userQuerySnapshot.docs[0];
                await updateDoc(docSnapshot.ref, { type: "lifetime" })  
                //console.log("User type updated to lifetime");
            } else {
                //console.log("User not found on checkoutyes");
            }
        } else {
            //console.log("User mismatch");
        }
    }

    useEffect(() => {
        handlePaymentYes(userid);
        updateTypeOnDb();
      }, [user]);

    return(
        <div className={`${styles.checkoutPage} d-flex align-items-center justify-content-center flex-column`}>
            <div className={styles.checkoutBox}>
                <h1 className='bold fontXXXL mb-1'>
                    Grazie per aver scelto Teseo!
                </h1>
                <span className='mb-5 justify-content-center d-flex regular'>E' ora di risolvere un nuovo labirinto</span>
                <Link to={'/greco'} className={styles.navLink}><GButton text={'Vai al Greco'}></GButton></Link>
            </div>
        </div>
    );
};

export default CheckoutYes;