import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth, usersCollection } from "../config/firebase-config";
import { addDoc, getDocs, query, where } from "firebase/firestore";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  const [userData, setUserData] = useState({});

  async function logIn(email, password, setError) {
    if (!email || !password) {
      setError("Inserisci email e password")
      return;
    }
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      const user = result.user;
      if (!user) {
        setError("Utente non trovato!");
        //console.log("Utente non trovato");
        return;
      } else {
        const userQuerySnapshot = await getDocs(query(usersCollection, where("uid", "==", user.uid)))
        if (userQuerySnapshot.size > 0) {
          const docSnapshot = userQuerySnapshot.docs[0];
          const data = { ...docSnapshot.data(), id: docSnapshot.id };
          setUserData(data); 
        }
        return user;
      }
    } catch (error) {
      if (error.message === "Firebase: Error (auth/invalid-credential).") {
        setError("Credenziali non valide");
      }
      //console.log(error.message);
    }
  }
  async function signUp(email, password, scuola, anno, nome) {
    const newUser = await createUserWithEmailAndPassword(auth, email, password);

    await sendEmailVerification(newUser.user)
      .then(() => {
        //console.log("Email inviata");
      })
      .then(() => {
        // Crea un nuovo utente nel db
        addUserToFirestore(newUser.user.uid, scuola, anno, nome);
      })
      .catch((error) => {
        //console.log(error);
      })
    return newUser;
  }
  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }
  function logOut() {
    return signOut(auth);
  }
  async function googleSignIn(scuola, anno, nome) {
    const googleAuthProvider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, googleAuthProvider);
    const user = result.user;

    // Controlla se esiste gia in Firestore
    const userQuerySnapshot = await getDocs(query(usersCollection, where("uid", "==", user.uid)))
    if (userQuerySnapshot.size > 0) {
      const docSnapshot = userQuerySnapshot.docs[0];
      const data = { ...docSnapshot.data(), id: docSnapshot.id };
      setUserData(data); 
    } else {
      // Aggiunge utente nel db
      await addUserToFirestore(user.uid, scuola, anno, nome);
    }
    return result;
  }
  async function addUserToFirestore(uid, scuola, anno, nome) {
    await addDoc(usersCollection, {
      uid: uid,
      usage_count: 0,
      latino_count: 0,
      type: "normal",
      nome: nome,
      scuola: scuola,
      anno: anno,
    });
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      // //console.log("Auth", currentuser);
      setUser(currentuser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{ user, userData, logIn, signUp, logOut, googleSignIn, resetPassword }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}