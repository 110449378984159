import React, {useState, useEffect, useRef} from 'react';
import {motion} from 'framer-motion';

import SliderCard from "./SliderCard";
import styles from "../styles/Slider.module.css"

const Slider = () => {
    const reviewsData = [ // Sample reviews data
    { text: "Perchè lo scopro solo ora?? Traduzioni accurate e analisi praticamente perfette, 10/10", author: "Elena (23)", place: "Lettere classiche @Unipa" },
    { text: "Se fai il liceo sarà il tuo nuovo sito preferito. Incredibile. lo uso TUTTI i giorni.", author: "Federico (18)", place: "Liceo Classico @Roma" },
    { text: "Non è un sito dove copi la versione e basta, con l’analisi puoi capire il perchè delle cose e migliorare veramente! Svolta per prepararsi alle interrogazioni di grammatica.", author: "Laura (19)", place: "Liceo Classico @Bologna" },
    { text: "Ma perchè sono nata nel momento storico sbagliato? voglio tornare indietro e rifare il liceo con Teseo. ", author: "Sara (22)", place: "Ex Studente Liceo Classico @Genova " },
    { text: "Teseo mio padre, mio fratello e anche mia madre.", author: "Gabriele (18)", place: "Liceo Scientifico @Torino" },
    { text: "La prima volta ho provato ad utilizzarla senza grandi aspettative, ora non ne posso fare a meno. L’analisi del testo è super utile. ", author: "Beatrice (18)", place: "Liceo Classico @Sanremo" },  
];    
    const [width, setWidth] = useState(0);
    const dragSlider = useRef();


    useEffect(() => {
        const updateWidth = () => {
            if (window.innerWidth <= 768) {
                // For mobile and small screens, set the width to the full scroll width
                setWidth(dragSlider.current.scrollWidth - dragSlider.current.offsetWidth);
            } else {
                // For desktop and larger screens, set the width to the difference between scroll width and offset width
                setWidth(dragSlider.current.scrollWidth - dragSlider.current.offsetWidth);
            }
        };
    
        // Initial width calculation
        updateWidth();
    
        // Update width on window resize
        window.addEventListener('resize', updateWidth);
    
        // Cleanup event listener
        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, []);



    return (
        <div className={styles.slider}>
            <div className={styles.slider_box}>
                <div className="text-center fontXXL bold">Dicono di Teseo</div>
                <motion.div className={styles.slider_box_items} ref={dragSlider}>
                    <motion.div
                        ref={dragSlider}
                        className={`${styles.slider_box_item} item_slider`}
                        drag="x"
                        dragConstraints={{right:0, left: -width}}
                    >
                    {reviewsData.map((review, i) => (
                        <SliderCard key={i} review={review} />
                    ))}
                    </motion.div>
                </motion.div>
            </div>
        </div>
    );
}

export default Slider;