// Login.js
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import { Button } from "react-bootstrap";
import GoogleButton from "react-google-button";
import { useUserAuth } from "../components/UserAuthContext";
import styles from "../styles/PopupTeseo.module.css";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const { logIn, googleSignIn, resetPassword, user } = useUserAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        try {
            const success = await logIn(email, password, setError);
            if(success) {
                navigate("/profilo");
            }
        } catch (err) {
            setError(err.message);
            //console.log(err.message);
        }
    };

    const handleGoogleSignIn = async (e) => {
        e.preventDefault();
        setError("");
        try {
            const success = await googleSignIn();
            if(success) {
                navigate("/profilo");
            }
        } catch (error) {
            setError(error.message);
            //console.log(error.message);
        }
    };

    const handleResetPassword = async () => {
        setError("");
        try {
            await resetPassword(email);
            setError("Email inviata, controlla la posta!");
            //console.log("Email inviata");
        } catch (error) {
            setError(error.message);
            //console.log(error.message);
        }
    }

    return (
        <>
        <div className={styles.normalSizeBox}>
            <div className="d-flex align-items-center justify-content-center">
                <div className={`${styles.boxTeseo} white p-5`}>
                    <h2 className="mb-3 bold text-center regular">Teseo è pronto, e tu?</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control
                                type="email"
                                placeholder="Indirizzo Email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>

                        <div className="d-grid gap-2">
                            <Button variant="primary" type="Submit" className="buttonTeseo bold fontL">
                                Log In
                            </Button>
                        </div>
                    </Form>
                    <hr />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <GoogleButton
                            className="g-btn"
                            type="light"
                            onClick={handleGoogleSignIn}
                        />
                    </div>

                    <div className="p-1 box mt-3 text-center regular fontM" onClick={handleResetPassword}>
                        Password dimenticata? <Link className="linkTeseo">Recupera</Link>
                    </div>
                    <div className="p-2 mt-3 text-center regular fontM">
                        Non hai ancora un account? <Link to="/signup" className="linkTeseo">Registrati</Link>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Login;
