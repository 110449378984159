import React from 'react';
import {motion} from 'framer-motion';

import styles from "../styles/SliderCard.module.css";

const SliderCard = ({ review }) => {
    return (
        <motion.div className={styles.sliderCard}>
            <div className={styles.sliderCard_box}>
                <motion.div className={styles.sliderCard_box_text}>
                    <div className='regular fontL white text-center'>{review.text}</div>
                    <div className='bold fontM text-center mt-4'>{review.author}</div>
                    <div className='fontM text-center'>{review.place}</div>
                </motion.div>
            </div>
            
        </motion.div>
    );
};

export default SliderCard;