import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'; // Import your main application component
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/shared.css';
import { initGA } from './components/Analytics'; // Import the initGA function

initGA();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);