import { usersCollection } from "../config/firebase-config";
import { getDocs, query, where } from "firebase/firestore";

export const getType = async(user) =>{
    const userQuerySnapshot = await getDocs(query(usersCollection, where("uid", "==", user.uid)))
    if (userQuerySnapshot.size > 0) {
      const docSnapshot = userQuerySnapshot.docs[0];
      const data = { ...docSnapshot.data(), id: docSnapshot.id };
      return data.type;
    }
}