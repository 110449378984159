import styles from '../styles/TextareaUser.module.css'
import React from 'react';
export default function TextareaUser(props) {

    const isReadOnly = props.readOnly;
    const placeholder = props.initialize;
    const lang = props.lang;

    return (
        
        <textarea
          name="postContent"
          placeholder={placeholder}
          rows={7}
          cols={10}
          maxLength={1500}
          onChange={props.setTextInput}
          className={`${styles.textareaUser} regular white fontL p-2 ${lang === "gre" ? styles.greTextarea : styles.latTextarea}`}
          readOnly={isReadOnly}
        />
        
    );
}
