import analyzeText from "./analyzeText";

const handleAnalyzeText = async (uid, inputText, lang, setLoading, setAnalysis, setProgress, setTextError, setError, handleUpdateError, handleUpdateAnalysis, setDisabled) => {
    try {
        setTextError("");
        setLoading(true);
        setDisabled(true);
        setAnalysis({}); // Reset analysis state
        setProgress(0); // Reset progress state
    
        await analyzeText(uid, inputText, lang, handleUpdateAnalysis, handleUpdateError); 
    
      } catch (error) {
        // Handle error (e.g., display error message)
        setError(true);
        setTextError(error.message);
    } finally {
        setLoading(false);
        setDisabled(false); 
    }
};


export default handleAnalyzeText;
