import axios from 'axios';
import divideText from './divideText';
import { addDoc, serverTimestamp } from "firebase/firestore";
const { analysisCollection } = require('../config/firebase-config');

//const API_URL = 'https://eureka-backend.vercel.app'
const API_URL = process.env.REACT_APP_BACKEND_PATH;

const TEXT_ERROR_MESSAGES = {
    400: 'Teseo traduce solo testi latini!',
    429: 'Teseo ha lavorato troppo per te oggi, torna domani.',
    default: 'Teseo si è perso nel labirinto e non può aiutarti ora.'
};

const analyzeText = async (uid, inputText, lang, updateUI, updateError) => {
    try {

        const subtexts = divideText(inputText); // Divide the text into subtexts
        let complete_response = {};
    
        for (let i = 0; i < subtexts.length; i++) {
            await axios.post(`${API_URL}/analyze`, { text: subtexts[i], lang:lang }).then(response => {
                // Trigger UI update with partial results
                updateUI(response.data, i, subtexts.length);
                complete_response = { ...complete_response, ...response.data };    
            })
            .catch(error => {
                // Handle API call error
                handleAnalysisError(error, updateError);
            });
        }
        await saveDb(uid, inputText, complete_response, lang);

    } catch (error) {
        handleAnalysisError(error, updateError);
    }
};

const handleAnalysisError = (error, updateError) => {
    let errorMessage = 'Teseo non risponde'; // Default error message

    if (error.response) {
        const statusCode = error.response.status;
        errorMessage = TEXT_ERROR_MESSAGES[statusCode] || TEXT_ERROR_MESSAGES.default;
        updateError(errorMessage);
    } else if (error.request) {
        console.error('Request Error:', error.request);
    } else {
        console.error('Request Setup Error:', error.message);
    }

    throw new Error(errorMessage);
};

const saveDb = async(uid, inputText, outputText, lang) => {
    try {
        await addDoc(analysisCollection, {  
            uid: uid,
            input: inputText,
            output: outputText,
            language: lang,
            timestamp: serverTimestamp() 
        });
    } catch (error) {
        console.log("ERROR on analysis db: ", error);
    }
}

export default analyzeText;
