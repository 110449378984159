import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useUserAuth } from "../components/UserAuthContext";
import {getType} from "../utils/profiloUtils";
import { useEffect, useState } from 'react';
import { MdVerified } from "react-icons/md";


const Home = () => {
  const { logOut, user } = useUserAuth();
  const [userType, setUserType] = useState('');

  const navigate = useNavigate();

    useEffect(() => {
        const fetchUserType = async () => {
            try {
                if (user) {
                    const type = await getType(user);
                    setUserType(type);
                }
            } catch (error) {
                console.error("Error fetching user type:", error);
            }
        };

        fetchUserType();
    }, [user]);

    if (userType === null) {
        return <p>Loading...</p>; 
    }

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      //console.log(error.message);
    }
  };

  const handleGreco = () => {
    navigate("/greco");
  };

  const handleLatino = () => {
    navigate("/");
  };

  function reload() {
    window.location.reload();
  }

  return (
    <>
    <div className="d-flex justify-content-center align-items-center flex-column">
      <div className="box_plain_teseo p-5">
        <div className="text-center bold fontXL">
        {userType==='lifetime' ? <div className="d-flex align-items-center justify-content-center"><MdVerified size={27} className='white mx-2'/><span>Teseo+ community</span></div> : <div>Teseo ti da il benvenuto!</div>}
        </div>
        <div className="p-2 mt-3 text-center regular">
          La tua mail: {user.email}
          {user.emailVerified ? (
            <p className="underline">La tua mail è verificata.</p>
          ) : (
            <>
              <p>Controlla il tuo Indirizzo di posta, ricordati di verificare il tuo profilo!</p>
              <Button variant="warning" onClick={reload}>Ho verificato!</Button>
            </>
          )}
        </div>
        <br />
        <div className="d-flex justify-content-center">
          <Button className="buttonTeseo regular mx-1" onClick={handleGreco} disabled={!user.emailVerified}>
            Vai al Greco
          </Button>
          <Button className="buttonTeseo regular" onClick={handleLatino} disabled={!user.emailVerified}>
            Vai al Latino
          </Button>
        </div>
        <div className="d-flex justify-content-center my-3">
          <a className="linkTeseo regular fontM text-center mt-3" onClick={handleLogout}>Log out</a>
        </div>
      </div>
      </div>
    </>
  );
};

export default Home;
