import styles from '../styles/GButton.module.css'

export default function GButton(props){
    const textButton = props.text; 

    return(
        <div className="d-flex justify-content-center align-items-center mx-3">
            <button className={`${styles.gbutton} white bold px-4 py-2`}>
                {textButton}
            </button>
        </div>
    )
}