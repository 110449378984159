import {Row, Col, Container} from 'react-bootstrap';
import {Link, useLocation } from 'react-router-dom';
import logo from "../images/logo/logo_white.svg";
import styles from "../styles/Navbar.module.css"
import GButton from './GButton';
import { useUserAuth } from '../components/UserAuthContext';
import {getType} from "../utils/profiloUtils";
import { MdVerified } from "react-icons/md";
import { useEffect, useState } from 'react';


export default function Navbar(props) {
    const location = useLocation();
    const { user } = useUserAuth();

    const [userType, setUserType] = useState('');

    useEffect(() => {
        const fetchUserType = async () => {
            try {
                if (user != '' && user != null) {
                    const type = await getType(user);
                    setUserType(type);
                } else {
                    setUserType('normal');
                }
            } catch (error) {
                console.error("Error fetching user type:", error);
            }
        };

        fetchUserType();
    }, [user]);

    if (userType === null) {
        return <p>Loading...</p>; 
    }

    const getButtonInfo = () => {
        // Check the current pathname and return the corresponding text and URL
        if (location.pathname === '/greco') {
            return { text: 'Passa al Latino', to: '/' };
        } else if (location.pathname === '/login') {
            return { text: 'Home', to: '/' };
        } else if (location.pathname === '/' && user) {
            return { text: 'Passa al Greco', to: '/greco' };
        } else if (location.pathname === '/') {
            return { text: 'Passa al Greco', to: '/signup' };
        }  
        else {
            return { text: 'Home', to: '/' };
        }
    };
    
    const { text, to } = getButtonInfo();

    return(
        <Container>
            <Row className='justify-content-center my-3'>   
                <Col className="align-items-center d-flex justify-content-center">
                    <img src={logo} alt="Logo" className={styles.nav_logo_image} />
                </Col>
                <Col className="align-items-center d-flex justify-content-center">
                    <Link to='/' className='white fontXL bold' style={{textDecoration: 'none'}}><span>Teseo.app</span></Link>
                </Col>
                <Col className="align-items-center d-flex justify-content-center">
                    { 
                    user ? 
                        userType==='lifetime' ?
                            <div className='d-flex justify-content-center align-items-center'><MdVerified size={27} className='white'/></div> 
                            : 
                            <div></div>
                            // <div className='buttonTeseo p-2'><Link to={'/pay'} className='bold fontM white' style={{textDecoration: 'none'}}>Teseo+</Link></div>
                        :
                    ''
                    }
                    
                    <Link to={to} className={styles.navLink}><GButton text={text}></GButton></Link>
                </Col>
            </Row>
        </Container>
    );
}