import React, { useState, useEffect } from 'react';
import { Col, Row, Container } from "react-bootstrap";
import ReactGA from 'react-ga4';

import TextAreaUser from '../components/TextareaUser';
import TButton from '../components/TButton';
import ResultBox from '../components/ResultBox';
import handleTranslateText from '../utils/translationUtils';
import handleAnalyzeText from '../utils/analyzeUtils';
import sanitizeInput from '../utils/generalUtils';

import { Circles } from 'react-loader-spinner'
import { useUserAuth } from '../components/UserAuthContext';


function Greco() {
    const { user } = useUserAuth();
    
    const [inputText, setInputText] = useState('Curate ut valeatis');
    const [textError, setTextError] = useState('');
    const [loading, setLoading] = useState(false);
    const [translatedText, setTranslatedText] = useState('');
    const [analysis, setAnalysis] = useState({});
    const [progress, setProgress] = useState(0); 
    const [error, setError] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [usageCounter, setUsageCounter] = useState(0);

    useEffect(() => {
      // Get contatore iniziale GRECO
      // async function fetchData() {
      //   const counter = await getCounterOnDb();
      //   setUsageCounter(counter);
      //   ////console.log("Counter iniziale: ", counter, "/70 volte");
      // }
      // fetchData();
      
      document.body.classList.add('greco-page');
      return () => {
        document.body.classList.remove('greco-page');
      };
    }, []);

    // async function getCounterOnDb() {
    //   if(user.uid === undefined) return;
    //   const userQuerySnapshot = await getDocs(query(usersCollection, where("uid", "==", user.uid)))
    //   if (userQuerySnapshot.size > 0) {
    //       const docSnapshot = userQuerySnapshot.docs[0];
    //       const data = { ...docSnapshot.data(), id: docSnapshot.id };
    //       //console.log("Counter iniziale: ", data.usage_count, "/70 volte");
    //       return data.usage_count;
    //   }
    // }

    // useEffect(() => {
    //   updateCounterOnDb();
    // }, [usageCounter]);

    // async function updateCounterOnDb() {
    //   if(user.uid === undefined) return;
    //   try {
    //     const userQuerySnapshot = await getDocs(query(usersCollection, where("uid", "==", user.uid)));
    //     if (userQuerySnapshot.size > 0) {
    //       const docSnapshot = userQuerySnapshot.docs[0];
    //       await updateDoc(docSnapshot.ref, { usage_count: usageCounter });
    //       //console.log("Counter aggiornato: ", usageCounter, "/70 crediti mensili usati");
    //     }
    //   } catch (error) {
    //     console.error("Error updating counter on Firestore:", error);
    //   }
    // }

    const handleUpdateError = (errorText) => {
      setTextError(errorText);
      setError(true);
    }
  
    const handleTranslate = async (lang) => {
  
      ReactGA.event({
        category: 'Button',
        action: 'Click greco translate',
        label: 'Translate greek text',
      });
      setUsageCounter(usageCounter + 1);
      handleTranslateText(user.uid, inputText, lang, setLoading, setTranslatedText, setError, setTextError, handleUpdateError, setDisabled);
    }

    const handleUpdateAnalysis = (partialData, i, len) => {
      // Update component state with partial analysis data
      setAnalysis(prevData => ({ ...prevData, ...partialData }));
      setProgress((i + 1) / len * 100);
    };
  
    const handleAnalysis = async (lang) => {
      ReactGA.event({
        category: 'Button',
        action: 'Click greco analisi',
        label: 'Analyzed greek text',
      });
      setUsageCounter(usageCounter + 1);
      handleAnalyzeText(user.uid, inputText, lang, setLoading, setAnalysis, setProgress, setTextError, setError, handleUpdateError, handleUpdateAnalysis, setDisabled);
    };
  
    const cleanInput = (event) => {
      let textSanitized = sanitizeInput(event);
      if(textSanitized === "") {
        setDisabled(true);
      }
      else {
        setInputText(textSanitized);
        setDisabled(false);
      }
    }

    const setGreco = (event) => {
      const plainText = event.target.value.replace(/(\r\n|\n|\r)/gm, "");
      setInputText(plainText);
      setDisabled(false);
    }

    return (
      <main>
        <Container>
            <Row className='my-5'>
            <span className='bold fontXXL white text-center'>Teseo ha risolto un nuovo labirinto!</span>  
            <span className='regular fontXL white text-center'>Traduci e analizza le tue versioni di greco con l'intelligenza artificiale</span>
          </Row>
          <Row className='align-items-center justify-content-center mb-5 mx-2'>
            <TextAreaUser initialize="Incolla qui la tua versione di greco" setTextInput={setGreco} readOnly={false} lang={"gre"}></TextAreaUser>

            <span className='text-center regular fontS white mt-1'>{textError}</span>
            
            <Col sm={11} md={8} lg={8} xs={11} className="d-flex justify-content-center mt-4">
              {loading ? 
                <Circles
                  height="40"
                  width="40"
                  color="#BB0A0B"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              : 
              ""
              }

              <TButton onClick={() => handleTranslate("gre")} text='Traduci' disabled={disabled}></TButton>
              <TButton onClick={() => handleAnalysis("gre")} text='Analizza' disabled={disabled}></TButton>
            
            </Col>
          </Row>

          <Row className='justify-content-center'>
              <ResultBox object={translatedText} isAnalisi={false} error={error}></ResultBox>
              <ResultBox object={analysis} isAnalisi={true} error={error} loading={loading} progress={progress}></ResultBox>
          </Row>
        </Container>
      </main>
    );
}

export default Greco;