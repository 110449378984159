import translateText from './translateText';
import { useUserAuth } from "../components/UserAuthContext";

const handleTranslateText = async (uid, inputText, lang, setLoading, setTranslatedText, setError, setTextError, handleUpdateError, setDisabled) => {
    try {
      setTextError("");
      setLoading(true);
      setDisabled(true);
      setTranslatedText('');

      const response = await translateText(uid, inputText, lang, handleUpdateError);
      setTranslatedText(response);
    
    } catch (error) {
      setError(true);
      //console.log(error);
      setTextError(error.message);
    } finally {
        setLoading(false);
        setDisabled(false);
    }
};


  export default handleTranslateText;